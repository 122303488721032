<template>
  <div id="user_form" v-if="faqData">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ faqData.id == 0 ? "Add" : "Edit" }} Faq
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <b-col md="12">
              <b-form-group label="Question" label-for="question" label-cols-md="1" class="required">
                <validation-provider #default="{ errors }" name="question" rules="required">
                  <b-form-input id="question" v-model="faqData.question" :state="errors.length > 0 ? false : null"
                    placeholder="Question" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Answer" label-for="answer" label-cols-md="1" class="required">
                <validation-provider #default="{ errors }" name="answer" rules="required">
                  <div class="quill-editor">
                    <quill-editor v-model="faqData.answer" :content="faqData.answer" :options="editorOption"
                      ref="QuillEditor">
                    </quill-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Sort Order" label-for="sort_order" label-cols-md="1" class="required">
                <validation-provider #default="{ errors }" name="sort_order" rules="required">
                  <b-form-input id="sort_order" v-model="faqData.sort_order" :state="errors.length > 0 ? false : null"
                    placeholder="Sort Order" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="is_active" label-cols-md="1">
                <validation-provider #default="{ errors }" name="is_active" rules="required">
                  <b-form-checkbox :checked="faqData.is_active ? true : false" @change="
                    faqData.is_active = !faqData.is_active
                    " class="custom-control-success" name="check-button" switch inline />
                  {{
                    faqData.is_active
                      ? "Active"
                      : "Active"
                  }}
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Button: Submit & Reset Button.-->

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-sm-2 mobile-w100"
              type="submit">
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide"
              class="mt-1 mt-sm-0 mobile-w100">
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  methods: {
    hide() {
      this.$router.replace("/faq/list");
    },
    onSubmit() {
      console.log("submit data", this.faqData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-faq/addFaq", this.faqData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/faq/list");
            })
            .catch((error) => {
              this.loading = false;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    faqData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props.faqData);
    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);

    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const loading = ref(false);

    const resetData = () => {
      emit("refetch-data");
    };
    const resetfaqData = () => {
      props.faqData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetfaqData
    );

    onMounted(() => { });

    const editorOption = {
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["link"],
          ],
          handlers: {
            image: (value) => {
              console.log("image", value);
              if (value) {
                document.querySelector(".avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            },
          },
        },
      },
    };

    return {
      loading,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetfaqData,
      uploadUrl,
      headerObj,
      editorOption,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
