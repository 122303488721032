var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.faqData)?_c('div',{attrs:{"id":"user_form"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h3',{staticClass:"mb-0 ml-0 color4 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.faqData.id == 0 ? "Add" : "Edit")+" Faq ")])]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',{staticClass:"mt-2 mt-sm-3"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Question","label-for":"question","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"question","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"question","state":errors.length > 0 ? false : null,"placeholder":"Question"},model:{value:(_vm.faqData.question),callback:function ($$v) {_vm.$set(_vm.faqData, "question", $$v)},expression:"faqData.question"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Answer","label-for":"answer","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"quill-editor"},[_c('quill-editor',{ref:"QuillEditor",attrs:{"content":_vm.faqData.answer,"options":_vm.editorOption},model:{value:(_vm.faqData.answer),callback:function ($$v) {_vm.$set(_vm.faqData, "answer", $$v)},expression:"faqData.answer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Sort Order","label-for":"sort_order","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"sort_order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sort_order","state":errors.length > 0 ? false : null,"placeholder":"Sort Order"},model:{value:(_vm.faqData.sort_order),callback:function ($$v) {_vm.$set(_vm.faqData, "sort_order", $$v)},expression:"faqData.sort_order"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"is_active","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"is_active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":_vm.faqData.is_active ? true : false,"name":"check-button","switch":"","inline":""},on:{"change":function($event){_vm.faqData.is_active = !_vm.faqData.is_active}}}),_vm._v(" "+_vm._s(_vm.faqData.is_active ? "Active" : "Active")+" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('hr'),_c('div',{staticClass:"d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-sm-2 mobile-w100",attrs:{"variant":"primary","type":"submit"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"16","icon":"CheckCircleIcon"}}),_vm._v(" Submit ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mt-sm-0 mobile-w100",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.hide}},[_vm._v(" Cancel ")])],1)],1)],1)]}}],null,false,2040510298)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }